import React, { Component } from 'react'
import Countdown from 'react-countdown-now'
import moment from 'moment'

import Head1 from '../images/Head1.jpg'
// import Time2 from '../images/Time2.jpg'
import Time2 from '../images/Time2-1.jpg'
// import Sponsor3 from '../images/Sponsor3.jpg'
import Sponsor3 from '../images/Sponsor3-2.jpg'
// import buttonRegister from '../images/Register3.jpg'
// import buttonRegister from '../images/closeRegister.jpg'
import buttonRegister from '../images/closeRegister1.jpg'
// import getBib4 from '../images/getBib4.jpg'
import Map5 from '../images/Map5.jpg'
import Souvenir6 from '../images/Souvenir6.jpg'
// import Modal7 from '../images/Modal7.jpg'
import Distance8 from '../images/Distance8.jpg'
import Award9 from '../images/Award9.jpg'
import Payment10 from '../images/Payment10.jpg'
// import Program11 from '../images/Program11.jpg'
import Raceday12 from '../images/Raceday12.jpg'

// Popup
import Map from '../images/img/Racemap1_3.6-x-2.4-m._Edit.jpg'
import Medal5 from '../images/img/Medal5K.jpg'
import Medal10 from '../images/img/Medal10K.jpg'
import Medal21 from '../images/img/Medal21K.jpg'
import Shirt21 from '../images/img/21K-Shirt.jpg'
import Shirt10 from '../images/img/10K-Shirt.jpg'
import Shirt5 from '../images/img/5K-Shirt.jpg'
import ShirtVIP from '../images/img/VIP-Shirt.jpg'


// PDF
import Hostel from '../images/pdf/Hotel.pdf'

import './style.css'

class HomePage extends Component {

  // componentDidMount() {
  //   this.interval = setInterval(() => {
  //     const startRegister = moment('2018-08-01 00:00', 'YYYY-MM-DD HH:mm')
  //     const endRegister = moment('2018-08-01 00:00', 'YYYY-MM-DD HH:mm')
  //     const startImg = moment('2018-08-01 00:00', 'YYYY-MM-DD HH:mm')
  //     const endImg = moment('2018-08-01 00:00', 'YYYY-MM-DD HH:mm')
  //     const setTimeRegister = moment().isBetween(startRegister, endRegister)
  //     const setTimeImage = moment().isBetween(startImg, endImg)
  //     this.setState({
  //       showImg: this.state.showImg,
  //       setTimeRegister,
  //       setTimeImage,
  //     })
  //     this.setState({
  //       endimg: this.state.endimg,
  //       setTimeRegister,
  //       setTimeImage,
  //     })
  //   }, 1000)
  // }
  // componentWillUnmount () {
  //   clearInterval(this.interval)
  // }
  state = {
    showMenu: false,
    map1: false,
    medal5: false,
    medal10: false,
    medal21: false,
    shirt21: false,
    shirt10: false,
    shirt5: false,
    shirtVIP: false,
  }

  // showimg = () => {
  //   this.setState({ imgOpen: false })
  // }

  // closeimg = () => {
  //   this.setState({ imgclose: true })
  // }

  handleShowMenu = () => {
    this.setState({
      showMenu: true
    })
  }
  handleHideMenu = () => {
    this.setState({
      showMenu: false
    })
  }

  mappopup1 = () => {
    this.setState({ map1 : true })
  }
  mappopupclose1 = () => {
    this.setState({ map1 : false })
  }
  // Medal
  medalpopup5 = () => {
    this.setState({ medal5 : true })
  }
  medalpopupclose5 = () => {
    this.setState({ medal5 : false })
  }
  medalpopup10 = () => {
    this.setState({ medal10 : true })
  }
  medalpopupclose10 = () => {
    this.setState({ medal10 : false })
  }
  medalpopup21 = () => {
    this.setState({ medal21 : true })
  }
  medalpopupclose21 = () => {
    this.setState({ medal21 : false })
  }
  // Shirt
  shirtpopup21 = () => {
    this.setState({ shirt21 : true  })
  }
  shirtpopupclose21 = () => {
    this.setState({ shirt21 : false  })
  }
  shirtpopup10 = () => {
    this.setState({ shirt10 : true  })
  }
  shirtpopupclose10 = () => {
    this.setState({ shirt10 : false  })
  }
  shirtpopup5 = () => {
    this.setState({ shirt5 : true  })
  }
  shirtpopupclose5 = () => {
    this.setState({ shirt5 : false  })
  }
  shirtpopupVIP = () => {
    this.setState({ shirtVIP : true  })
  }
  shirtpopupcloseVIP = () => {
    this.setState({ shirtVIP : false  })
  }

  render() {

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // const hournow = moment().format("HH");
        // const minnow = moment().format("mm");

        // console.log(hournow[1])
        // console.log(hournow[2])
        // Render a completed state
        return <div className="countdown">
                <div className="day">0</div>
                <div className="countdown-1">:</div>
                <div className="hours">0</div>
                <div className="countdown-2">:</div>
                <div className="minutes">0</div>
                <div className="countdown-3">:</div>
                <div className="second">0</div>
               </div>;
      } 
        // Render a countdown
        return <div className="countdown">
               <div className="day">{days}</div>
               <div className="countdown-1">:</div>
               <div className="hours">{hours}</div>
               <div className="countdown-2">:</div>
               <div className="minutes">{minutes}</div>
               <div className="countdown-3">:</div>
               <div className="second">{seconds}</div></div>;
      
    }

    return (
      <div>
        <header>
        
        <div className="header-menu">
            <a href="#Home" className="logo-link" />
            
          <ul className="nav">
           
            <li className="dropdown" onMouseOver={this.showMenu} onMouseOut={this.hideMenu} > 
              <a href="javascript:void(0)" target="_blank" rel="noopener" >กดเพื่อสมัคร</a>
                <div className="dropdown-content" style={{ marginLeft: '-40px' }}>
                  <a className="nodead_cell" href="https://race.thai.run/PKRS3" target="_blank"  >สมัครวิ่ง (ปิดรับสมัคร)</a>  
                  <a className="nodead_cell" href="https://race.thai.run/PKRS3/register" target="_blank"  >สมัครวิ่งบัตรการกุศล (ปิดรับสมัคร)</a>  
                  <a className="nodead_cell" href="https://race.thai.run/dashboard" target="_blank"  >ตรวจสอบผลการสมัคร</a>
                  <a className="nodead_cell" href="#Payment">ราคาและการชำระเงิน</a>
                </div>
                
            </li>

            <li className="dropdown" onMouseOver={this.showMenu} onMouseOut={this.hideMenu}> 
              <a href="javascript:void(0)"  target="_blank" rel="noopener">ข้อมูลทั่วไป</a>
                <div className="dropdown-content" style={{ marginLeft: '-80px' }} >
                  <a className="nodead_cell" href="#Map">แผนที่การแข่งขัน</a>
                  <a className="nodead_cell" href="#Distance">ระยะแข่งขัน และ รุ่นกลุ่มอายุ</a>
                  <a className="nodead_cell" href="#Award">เงินรางวัลและถ้วยรางวัล</a>
                  <a className="nodead_cell" href="./RaceRules" >กฎกติกาการแข่งขัน</a>
                  <a className="nodead_cell" href="./AwardRules" >กติการการรับรางวัล</a>
                  <a className="nodead_cell" href="#Souvenir">แบบของที่ระลึก</a>
                  <a className="nodead_cell" href="#Raceday">กำหนดการ</a>
                  <a className="nodead_cell" href="./Protection">สิทธิ์การคุ้มครองประกันอุบัติเหตุ</a>
                  <a className="nodead_cell" href="./Parking">จุดจอดรถและบริการรับส่ง</a>
                </div>  
            </li>

            <li className="dropdown" onMouseOver={this.showMenu} onMouseOut={this.hideMenu} >
              <a href="javascript:void(0)">ที่พัก&amp;การเดินทาง</a>
                <div className="dropdown-content" style={{ marginLeft: '20px' }} >
                  <a className="nodead_cell" href={Hostel}>ที่พักใกล้เคียง</a>
                  <a className="nodead_cell" href="/Transportations">การเดินทาง</a>
                </div>
            </li>

            <li><a className="dropdown" target="_blank" href="https://photo.thai.run/PKRS3" >ค้นหาภาพถ่าย</a></li>
            
            <li className="dropdown" onMouseOver={this.showMenu} onMouseOut={this.hideMenu} >
            <a href="javascript:void(0)">ฟินิชเชอร์ฮับ</a>
                <div className="dropdown-content" style={{ marginLeft: '-60px' }} >
                  {/* <a className="dead_cell" >ค้นหาภาพถ่าย</a> */}
                  <a className="nodead_cell" href="/result" target="_blank">ตรวจสอบผลการแข่งขัน</a>
                  {/* <a className="dead_cell" >e-slip</a>
                  <a className="dead_cell" >e-Certificate</a> */}
                </div>
            </li>

          </ul>
        </div>
      </header>
      <div className="setCountdown"  onMouseOver={this.showMenu} onMouseOut={this.hideMenu} >
                    <Countdown
                       date={moment('2019-07-07 05:00', 'YYYY-MM-DD HH:mm').toString()}
                       renderer={renderer} 
                     />
            </div>

      <a href="#top" className="top"
        style={{  
                position:'fixed', 
                bottom:'30px', 
                right:'150px'
              }} 
      > 
        กลับไปด้านบน
      </a>

      
        <div className="header-content">
          <div id="Home" className="topHome" />      
          <div id="top"  className="topHome" />
          <img src={Head1} alt="img" className="content" useMap="#map-head" />

          <img src={Time2} alt="img" className="content" />
          <img src={Sponsor3} alt="img" className="content" useMap="#map-sponsor" />

          <img src={buttonRegister} alt="img" className="content" useMap="#image-map" />


          {/* <div id="*" className="scroll" /> */}
          {/* <img src={getBib4} alt="img" className="content" /> */}

          <div id="Map" className="scroll" />
          <img src={Map5} alt="img" className="content" useMap="#map-map5" />

          <div id="Souvenir" className="scroll" />
          <img src={Souvenir6} alt="img" className="content" useMap="#map-Souvenir" />

          {/* <div id="*" className="scroll" />
          <img src={Modal7} alt="img" className="content" /> */}

          <div id="Distance" className="scroll" />
          <img src={Distance8} alt="img" className="content" />

          <div id="Award" className="scroll" />
          <img src={Award9} alt="img" className="content" useMap="#map-Award" />

          <div id="Payment" className="scroll" />
          <img src={Payment10} alt="img" className="content" useMap="#map-Payment" />

          {/* <div id="Program" className="scroll" /> */}
          {/* <img src={Program11} alt="img" className="content" /> */}

          <div id="Raceday" className="scroll" />
          <img src={Raceday12} alt="img" className="content" useMap="#map-Raceday" />
        </div>

        <map name="map-head">
          <area target="_blank" alt="img" title="" href="https://line.me/R/ti/p/@PrachuapKhiriRun" coords="249,646,450,687" shape="rect" />
          <area target="_blank" alt="img" title="" href="https://www.facebook.com/PrachuapKhiriRun" coords="520,642,925,686" shape="rect" />
        </map>

        {/* <map name="map-sponsor">
          <area target="_blank" alt="img" title="" href="" coords="856,1798,969,1991" shape="rect" />
          <area target="_blank" alt="img" title="" href="https://race.thai.run/PKRS3" coords="577,1234,1059,1509" shape="rect" />
        </map> */}

        <map name="map-map5">
          <area alt="img" title="" coords="167,124,930,614" shape="rect" onClick={this.mappopup1} />
        </map>

        <map name="image-map">
    {/* <area target="_blank" alt="" title="" href="https://race.thai.run/PKRS3/register" coords="577,30,1054,308" shape="rect" /> */}
    <area target="_blank" alt="" title="" href="https://www.google.co.th/maps/place/11%C2%B046'17.4%22N+99%C2%B047'42.6%22E/@11.771507,99.7929703,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d11.771507!4d99.795159" coords="840,592,976,782" shape="rect" />
        </map>

        <map name="map-Award">
          <area alt="img" title="" href="./AwardRules" coords="395,981,521,1033" shape="rect" />
          <area alt="img" title="" href="./RaceRules" coords="774,1394,1016,1487" shape="rect" />
        </map>

        <map name="map-Souvenir">
          <area alt="img" title="" coords="186,268,626,597" shape="rect" onClick={this.shirtpopup21} />
          <area alt="img" title="" coords="466,628,908,955" shape="rect" onClick={this.shirtpopup10} />
          <area alt="img" title="" coords="189,978,627,1307" shape="rect" onClick={this.shirtpopup5} />
          <area alt="img" title="" coords="469,1332,910,1661" shape="rect" onClick={this.shirtpopupVIP} />
          <area alt="img" title="" coords="50,3331,371,3650" shape="rect" onClick={this.medalpopup21} />
          <area alt="img" title="" coords="393,3331,713,3650" shape="rect" onClick={this.medalpopup10} />
          <area alt="img" title="" coords="736,3331,1057,3651" shape="rect" onClick={this.medalpopup5} />
        </map>

        <map name="map-Payment">
          {/* <area target="" alt="" title="" href="" coords="864,601,1028,685" shape="rect" /> */}
          <area target="" alt="img" title="" href="./RaceRules" coords="772,1194,1021,1287" shape="rect" />
        </map>

        <map name="map-Raceday">
          <area target="_blank" alt="img" title="" href="https://www.google.co.th/maps/place/11%C2%B046'17.4%22N+99%C2%B047'42.6%22E/@11.7715,99.792978,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d11.771507!4d99.795159" coords="810,192,910,350" shape="rect" />
          <area target="_blank" alt="img" title="" href="https://www.google.co.th/maps/place/11%C2%B046'17.4%22N+99%C2%B047'42.6%22E/@11.771507,99.7929703,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d11.771507!4d99.795159" coords="914,1030,1045,1238" shape="rect" />
          <area alt="img" title="" rel="noopener" href="./RaceRules"  coords="400,1922,523,1984" shape="rect" />
          <area target="" alt="img" title="" href="https://race.thai.run/PKRS3" coords="835,2422,999,2491" shape="rect" />
          <area target="" alt="img" title="" href="/Parking" coords="731,2716,988,2806" shape="rect" />
          <area target="_blank" alt="img" title="" href="https://www.facebook.com/PrachuapKhiriRun" coords="232,3077,757,3111" shape="rect" />
          <area target="_blank" alt="img" title="" href="https://line.me/R/ti/p/@PrachuapKhiriRun" coords="193,3124,428,3156" shape="rect" />
        </map>

        {this.state.map1 ? <div className="popup" onClick={this.mappopupclose1}>
          <img src={Map} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.medal5 ? <div className="popup" onClick={this.medalpopupclose5}>
          <img src={Medal5} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.medal10 ? <div className="popup" onClick={this.medalpopupclose10}>
          <img src={Medal10} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.medal21 ? <div className="popup" onClick={this.medalpopupclose21}>
          <img src={Medal21} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.shirt21 ? <div className="popup" onClick={this.shirtpopupclose21}>
          <img src={Shirt21} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.shirt10 ? <div className="popup" onClick={this.shirtpopupclose10}>
          <img src={Shirt10} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.shirt5 ? <div className="popup" onClick={this.shirtpopupclose5}>
          <img src={Shirt5} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}
        {this.state.shirtVIP ? <div className="popup" onClick={this.shirtpopupcloseVIP}>
          <img src={ShirtVIP} alt="img" style={{marginTop: '-350px', maxWidth: '700px'}} />
        </div> : ""}

      </div>
    )
  }
}

export default HomePage
